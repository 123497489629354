import React from 'react'
import Fade from 'react-reveal/Fade';
import './Meth.css'

const Meth = () => {
    return (
        <>
        <div className='meth'>

                <div className="meth-content">
                    <Fade top>
                    <h1>METH ADDICTION</h1>
                    <h3>SUBSTANCE ABUSE</h3>

                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Meth
