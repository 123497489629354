import React from 'react'
import Fade from 'react-reveal/Fade';
import './Opiate.css'

const Opiate = () => {
    return (
        <>
        <div className='opiate'>

                <div className="opiate-content">
                    <Fade top>
                    <h1>OPIATES ADDICTION</h1>
                    <h3>SUBSTANCE ABUSE</h3>

                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Opiate
