import React from 'react'
import Fade from 'react-reveal/Fade';

import './Cocaine.css'



const Cocaine = () => {
    return (
        <>
        <div className='cocaine'>
                <div className="cocaine-content">
                    <Fade top>
                    <h1>COCAINE ADDICTION</h1>
                    <h3>SUBSTANCE ABUSE</h3>

                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Cocaine
